import endpoints from './endpoints';
import configStore from '../redux/store/configStore';
import { logout } from '../redux/actions/authAction';
import { internetFlag } from '../redux/actions/internetFlagAction';

import { decryptString } from './commonHelper';

const axios = require('axios');

class ApiUtils {
  constructor(
    defineRequestInterceptor = false,
    appendAuth = false,
    defineResponseInterceptor = false,
    props
  ) {
    this.axios = axios.create({});
    if (window.navigator.onLine) {
      if (defineRequestInterceptor) {
        // main
        const hostname = window && window.location && window.location.hostname;

        let backendURL;
        let backendToken;
        let backendDomain = hostname;

        // host base URL
        const scheme = hostname.match(/^(.*\.)?localhost$/) ? 'http' : 'https';
        backendURL = process.env.REACT_APP_API_URL || `${scheme}://${hostname}/api/`;

        if (appendAuth) {
          backendDomain = hostname;
          let store = configStore();
          const auth = store.getState();
          if (auth.auth.isLogin) {
            backendToken = undefined;
            backendToken = 'token ' + auth.auth.user.token + ' ' + hostname;
          }
        } else {
          let path;
          if (window.location.search) {
            if (window.location.search.includes('encrypt=true&token=')) {
              path = window.location.search.replace('?encrypt=true&token=', '');
              path = decryptString(path);
            } else {
              path = window.location.search.replace('?token=', '');
            }
          }
          // for domain redirection
          if (path && window.location.pathname.includes('login')) {
            backendToken = 'token ' + path + ' ' + backendDomain;
          }
          backendDomain = hostname;
        }

        this.axios.interceptors.request.use(
          function (config) {
            let r = new RegExp('^(?:[a-z]+:)?//', 'i');
            config = {
              ...config,
              headers: {
                ...config.headers,
                'Content-Type':
                  config.headers['Content-Type'] === 'multipart/form-data'
                    ? config.headers['Content-Type']
                    : 'application/json',
                // "Access-Control-Allow-Origin": "*"
              },
              url: r.test(config.url) ? config.url : backendURL + config.url,
            };
            if (appendAuth) {
              if (backendToken) {
                config.headers['Authorization'] = backendToken;
              }
              config.headers['domain'] = backendDomain;
              config.headers['Page-Url'] = window?.location?.href;
            } else {
              config.headers['domain'] = backendDomain;
              config.headers['Page-Url'] = window?.location?.href;
              // for domain redirection
              if (window.location.pathname.includes('login') && window.location.search) {
                if (backendToken) {
                  config.headers['Authorization'] = backendToken;
                }
              }
            }

            return config;
          },
          function (error) {
            return Promise.reject(error);
          }
        );
      }
      this.axios.interceptors.response.use(
        function (response, error) {
          if (defineResponseInterceptor) {
            return response;
          } else {
            return response;
          }
        },
        async function (error) {
          if (error.response) {
            let internal_server_error = error.response.status >= 500;
            if (internal_server_error) {
              window.location.replace(`${window.location.origin}/500`);
            }
            if ([401].includes(error.response.status)) {
              let store = configStore();
              store.dispatch(logout());
              let error_message = error.response.data.message;
              let error_message_title =
                error.response.data.result.message_title || 'Session Expired';
              setTimeout(() => {
                window.location.replace(
                  `${window.location.origin}/401?error_title=${error_message_title}&error_message=${error_message}`
                );
              }, 1000);

              // Below wait will give us time to redirect to login page and
              // don't goes into other `else` conditions defined in
              // elsewhere in codebase (SY-4405)
              await new Promise(resolve => setTimeout(resolve, 3000));
            }
            // if ([404].includes(error.response.status)) {
            //   //
            //   window.location.replace(`${window.location.origin}/404`);
            // }
            return error.response;
          }
          return Promise.reject(error);
        }
      );
    } else {
      let store = configStore();
      store.dispatch(internetFlag({ is_internet_on: window?.navigator?.onLine }));
      window.location.reload();
      // App.noInternet(window?.navigator?.onLine);
    }
  }
  getTaskDataByClaimId = (claimId, params) => {
    return this.axios({
      method: endpoints.getOverViewTaskData.method,
      url: endpoints.getOverViewTaskData.url + claimId + '/get_task_data',
      params,
    });
  };
  getDocumentDataByClaimId = (claimId, params) => {
    return this.axios({
      method: endpoints.getDocumentDataByClaimId.method,
      url: endpoints.getDocumentDataByClaimId.url + claimId + '/get_document_data',
      params,
    });
  };
  getBudgetDataByClaimId = params => {
    return this.axios({
      method: endpoints.getBudgetDataByClaimId.method,
      url: endpoints.getBudgetDataByClaimId.url,
      params,
    });
  };
  getReserveDataByClaimId = params => {
    return this.axios({
      method: endpoints.getReserveDataByClaimId.method,
      url: endpoints.getReserveDataByClaimId.url,
      params,
    });
  };
  getClaimValuationByClaimId = params => {
    return this.axios({
      method: endpoints.getClaimValuationByClaimId.method,
      url: endpoints.getClaimValuationByClaimId.url,
      params,
    });
  };
  getPossibleCoverageByClaimId = params => {
    return this.axios({
      method: endpoints.getPossibleCoverageByClaimId.method,
      url: endpoints.getPossibleCoverageByClaimId.url,
      params,
    });
  };
  getLiensDataByClaimId = params => {
    return this.axios({
      method: endpoints.getLiensDataByClaimId.method,
      url: endpoints.getLiensDataByClaimId.url,
      params,
    });
  };
  getQuickViewDataById = (claimId, params) => {
    return this.axios({
      method: endpoints.getQuickViewData.method,
      url: endpoints.getQuickViewData.url + claimId + '/get_quick_view',
      params,
    });
  };
  getAllClaimsCall = (data, params) => {
    return this.axios({
      method: endpoints.getAllClaims.method,
      url: endpoints.getAllClaims.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  ColumnMetricsData = (data, params) => {
    return this.axios({
      method: endpoints.ColumnMetricsData.method,
      url: endpoints.ColumnMetricsData.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  getClaimById = params => {
    return this.axios({
      method: endpoints.getClaimById.method,
      url: endpoints.getClaimById.url + params,
    });
  };
  getClaimTopBarById = params => {
    return this.axios({
      method: endpoints.getClaimTopBarById.method,
      url: endpoints.getClaimTopBarById.url + params + '/get_overview_top_bar',
    });
  };
  getClaimValuationsById = params => {
    return this.axios({
      method: endpoints.getClaimValuationsById.method,
      url: endpoints.getClaimValuationsById.url,
      params,
    });
  };
  getClaimValuationsByPlaintiffId = params => {
    return this.axios({
      method: endpoints.getClaimValuationsByPlaintiffId.method,
      url: endpoints.getClaimValuationsByPlaintiffId.url + params,
    });
  };
  getClaimValuationsForExport = params => {
    return this.axios({
      method: endpoints.getClaimValuationsForExport.method,
      url: endpoints.getClaimValuationsForExport.url,
      params,
    });
  };
  getLiensByPlaintiffId = params => {
    return this.axios({
      method: endpoints.getLiensByPlaintiffId.method,
      url: endpoints.getLiensByPlaintiffId.url + params,
    });
  };
  getLiensDataForExport = params => {
    return this.axios({
      method: endpoints.getLiensDataForExport.method,
      url: endpoints.getLiensDataForExport.url,
      params,
    });
  };
  getClaimNumberById = params => {
    return this.axios({
      method: endpoints.getClaimById.method,
      url: endpoints.getClaimNumberById.url + params,
    });
  };
  getLineById = params => {
    return this.axios({
      method: endpoints.getLineById.method,
      url: endpoints.getLineById.url,
      params,
    });
  };
  patchClaimById = (currentId, params, data) => {
    return this.axios({
      method: endpoints.patchClaimById.method,
      url: endpoints.patchClaimById.url + currentId,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  updateFilter = (data, params) => {
    return this.axios({
      method: endpoints.updateFilter.method,
      url: endpoints.updateFilter.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  getFilter = params => {
    return this.axios({
      method: endpoints.getFilter.method,
      url: endpoints.getFilter.url,
      params,
    });
  };
  updateFilter = (data, params) => {
    return this.axios({
      method: endpoints.updateFilter.method,
      url: endpoints.updateFilter.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  getClaimTaskFilters = (currentId, params) => {
    return this.axios({
      method: endpoints.getClaimTaskFilters.method,
      url: endpoints.getClaimTaskFilters.url + currentId,
      params,
    });
  };
  updateTaskFilter = (currentId, data, params) => {
    return this.axios({
      method: endpoints.updateTaskFilter.method,
      url: endpoints.updateTaskFilter.url + currentId,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  getSort = params => {
    return this.axios({
      method: endpoints.getSort.method,
      url: endpoints.getSort.url,
      params,
    });
  };
  updateSort = (data, params) => {
    return this.axios({
      method: endpoints.updateSort.method,
      url: endpoints.updateSort.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  columnMetrics = (data, params) => {
    return this.axios({
      method: endpoints.columnMetrics.method,
      url: endpoints.columnMetrics.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  columnMetricsGetdata = data => {
    return this.axios({
      method: endpoints.columnMetricsGetdata.method,
      url: endpoints.columnMetricsGetdata.url,
      data: JSON.stringify({ ...data }),
    });
  };
  columnsUpdate = (data, params) => {
    return this.axios({
      method: endpoints.columnsUpdate.method,
      url: endpoints.columnsUpdate.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  columnsHeaders = () => {
    return this.axios({
      method: endpoints.columnsHeaders.method,
      url: endpoints.columnsHeaders.url,
    });
  };
  patchClaimViewDisplayDetails = data => {
    return this.axios({
      method: endpoints.patchClaimViewDisplayDetails.method,
      url: endpoints.patchClaimViewDisplayDetails.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getStaticPageCall = params => {
    return this.axios({
      method: endpoints.getStaticPageCall.method,
      url: endpoints.getStaticPageCall.url + params,
    });
  };
  patchClaimValuationNote = (currentId, data) => {
    return this.axios({
      method: endpoints.patchClaimValuationNote.method,
      url: endpoints.patchClaimValuationNote.url + currentId + '/edit_notes',
      data: JSON.stringify({ ...data }),
    });
  };
  patchDamageByPlaintiff = data => {
    return this.axios({
      method: endpoints.patchDamageByPlaintiff.method,
      url: endpoints.patchDamageByPlaintiff.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getMatterContacts = params => {
    return this.axios({
      method: endpoints.getMatterContacts.method,
      url: endpoints.getMatterContacts.url + params + '/get_matter_contacts',
    });
  };
  getUserRole = () => {
    return this.axios({
      method: endpoints.getUserRole.method,
      url: endpoints.getUserRole.url,
    });
  };

  patchLienByPlaintiff = (params, data) => {
    return this.axios({
      method: endpoints.patchLienByPlaintiff.method,
      url: endpoints.patchLienByPlaintiff.url + params + '/bulk_update',
      data: JSON.stringify({ ...data }),
    });
  };

  addClaimByPlaintiff = data => {
    return this.axios({
      method: endpoints.addClaimByPlaintiff.method,
      url: endpoints.addClaimByPlaintiff.url,
      data: JSON.stringify({ ...data }),
    });
  };
  patchLiabilityByClaimValuation = (params, data) => {
    return this.axios({
      method: endpoints.patchLiabilityByClaimValuation.method,
      url: endpoints.patchLiabilityByClaimValuation.url + params,
      data: JSON.stringify({ ...data }),
    });
  };
  addCustomCategoryForClaim = data => {
    return this.axios({
      method: endpoints.addCustomCategoryForClaim.method,
      url: endpoints.addCustomCategoryForClaim.url,
      data: JSON.stringify({ ...data }),
    });
  };
  postRegisterStep = (params, data) => {
    return this.axios({
      method: endpoints.postRegisterStep.method,
      url: endpoints.postRegisterStep.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  postResetPassword = data => {
    return this.axios({
      method: endpoints.postResetPassword.method,
      url: endpoints.postResetPassword.url,
      data: JSON.stringify({ ...data }),
    });
  };
  postLogin = data => {
    return this.axios({
      method: endpoints.postLogin.method,
      url: endpoints.postLogin.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getTokenLogin = data => {
    return this.axios({
      method: endpoints.getTokenLogin.method,
      url: endpoints.getTokenLogin.url + data,
    });
  };
  getNewVerificationCode = params => {
    return this.axios({
      method: endpoints.getNewVerificationCode.method,
      url: endpoints.getNewVerificationCode.url,
      params,
    });
  };
  postNewPassword = data => {
    return this.axios({
      method: endpoints.postNewPassword.method,
      url: endpoints.postNewPassword.url,
      data: JSON.stringify({ ...data }),
    });
  };
  loggedUserResetPassword = data => {
    return this.axios({
      method: endpoints.loggedUserResetPassword.method,
      url: endpoints.loggedUserResetPassword.url,
      data: JSON.stringify({ ...data }),
    });
  };
  validatePassword = data => {
    return this.axios({
      method: endpoints.validatePassword.method,
      url: endpoints.validatePassword.url + data,
    });
  };
  checkToken = (params, data) => {
    return this.axios({
      method: endpoints.checkToken.method,
      url: endpoints.checkToken.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  updateReserve = (currentId, data) => {
    return this.axios({
      method: endpoints.updateReserve.method,
      url: endpoints.updateReserve.url + currentId,
      data: JSON.stringify({ ...data }),
    });
  };
  addReserve = data => {
    return this.axios({
      method: endpoints.addReserve.method,
      url: endpoints.addReserve.url,
      data: JSON.stringify({ ...data }),
    });
  };
  // contacts
  getAllContactCall = (data, params) => {
    return this.axios({
      method: endpoints.getAllContactCall.method,
      url: endpoints.getAllContactCall.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  createGlobalContact = (params, data) => {
    return this.axios({
      method: endpoints.createGlobalContact.method,
      url: endpoints.createGlobalContact.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  addCustomCategoryForContact = data => {
    return this.axios({
      method: endpoints.addCustomCategoryForContact.method,
      url: endpoints.addCustomCategoryForContact.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getAllClaimDropdownCall = params => {
    return this.axios({
      method: endpoints.getAllClaimDropdownCall.method,
      url: endpoints.getAllClaimDropdownCall.url,
      params,
    });
  };

  updateBudgetBill = (currentId, budgetId, data) => {
    return this.axios({
      method: endpoints.updateBudgetBill.method,
      url: endpoints.updateBudgetBill.url + currentId + '/Budget/' + budgetId,
      data: JSON.stringify({ ...data }),
    });
  };
  getBudgetTypeAndCode = () => {
    return this.axios({
      method: endpoints.getBudgetTypeAndCode.method,
      url: endpoints.getBudgetTypeAndCode.url,
    });
  };
  getBudget = currentId => {
    return this.axios({
      method: endpoints.getBudget.method,
      url: endpoints.getBudget.url + currentId + '/Budget',
    });
  };
  addBudget = (currentId, data) => {
    return this.axios({
      method: endpoints.addBudget.method,
      url: endpoints.addBudget.url + currentId + '/Budget',
      data: JSON.stringify({ ...data }),
    });
  };
  addBudgetTypeAndCode = data => {
    return this.axios({
      method: endpoints.addBudgetTypeAndCode.method,
      url: endpoints.addBudgetTypeAndCode.url,
      data: JSON.stringify({ ...data }),
    });
  };

  ActivateGlobalContactStatusCall = (id, data) => {
    return this.axios({
      method: endpoints.ActivateGlobalContactStatusCall.method,
      url: endpoints.ActivateGlobalContactStatusCall.url + id + '/activate_deactivate',
      data: JSON.stringify({ ...data }),
    });
  };
  ResendInviteContactCall = (id, data) => {
    return this.axios({
      method: endpoints.ResendInviteContactCall.method,
      url: endpoints.ResendInviteContactCall.url + id + '/resend_invite',
      data: JSON.stringify({ ...data }),
    });
  };

  removeFromClaim = (id, params) => {
    return this.axios({
      method: endpoints.removeFromClaim.method,
      url: endpoints.removeFromClaim.url + id + '/remove_from_claim',
      params,
    });
  };

  editGlobalContact = (id, params, data) => {
    return this.axios({
      method: endpoints.editGlobalContact.method,
      url: endpoints.editGlobalContact.url + id,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  getClaimCoverageById = params => {
    return this.axios({
      method: endpoints.getClaimCoverageById.method,
      url: endpoints.getClaimCoverageById.url + params,
    });
  };
  addPolicyByDefendentId = data => {
    return this.axios({
      method: endpoints.addPolicyByDefendentId.method,
      url: endpoints.addPolicyByDefendentId.url,
      data: JSON.stringify({ ...data }),
    });
  };
  editPolicyByDefendentId = (data, id) => {
    return this.axios({
      method: endpoints.editPolicyByDefendentId.method,
      url: endpoints.editPolicyByDefendentId.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  deletePolicyByDefendentId = id => {
    return this.axios({
      method: endpoints.deletePolicyByDefendentId.method,
      url: endpoints.deletePolicyByDefendentId.url + id,
    });
  };
  getAllContactByClaimCall = (data, params) => {
    return this.axios({
      method: endpoints.getAllContactByClaimCall.method,
      url: endpoints.getAllContactByClaimCall.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  getCompanyContactByClaimDropCall = params => {
    return this.axios({
      method: endpoints.getCompanyContactByClaimDropCall.method,
      url: endpoints.getCompanyContactByClaimDropCall.url,
      params,
    });
  };
  editBudgetDetails = (currentId, budgetId, data) => {
    return this.axios({
      method: endpoints.editBudgetDetails.method,
      url:
        endpoints.editBudgetDetails.url + currentId + '/Budget/' + budgetId + '/EditBudgetDetails',
      data: JSON.stringify({ ...data }),
    });
  };
  deleteBudget = (currentId, budgetId) => {
    return this.axios({
      method: endpoints.deleteBudget.method,
      url: endpoints.deleteBudget.url + currentId + '/Budget/' + budgetId,
    });
  };
  addContactForClaimAccess = data => {
    return this.axios({
      method: endpoints.addContactForClaimAccess.method,
      url: endpoints.addContactForClaimAccess.url,
      data: JSON.stringify(data),
    });
  };
  createClaimContact = (params, data) => {
    return this.axios({
      method: endpoints.createClaimContact.method,
      url: endpoints.createClaimContact.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  getClaimDropDownMetaData = () => {
    return this.axios({
      method: endpoints.getClaimDropDownMetaData.method,
      url: endpoints.getClaimDropDownMetaData.url,
    });
  };
  addClaimMatterInformation = data => {
    return this.axios({
      method: endpoints.addClaimMatterInformation.method,
      url: endpoints.addClaimMatterInformation.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getAllDocumentByClaimCall = (currentId, params, folderID) => {
    return this.axios({
      method: endpoints.getAllDocumentByClaimCall.method,
      url: folderID
        ? endpoints.getAllDocumentByClaimCall.url + currentId + '/Documents/' + folderID
        : endpoints.getAllDocumentByClaimCall.url + currentId + '/Documents',
      params,
    });
  };
  getS3Token = () => {
    return this.axios({
      method: endpoints.getS3Token.method,
      url: endpoints.getS3Token.url,
    });
  };
  getNegotiationById = params => {
    return this.axios({
      method: endpoints.getNegotiationById.method,
      url: endpoints.getNegotiationById.url,
      params,
    });
  };
  editNegotiationNote = (data, currentId) => {
    return this.axios({
      method: endpoints.editNegotiationNote.method,
      url: endpoints.editNegotiationNote.url + currentId,
      data: JSON.stringify({ ...data }),
    });
  };
  patchNegotiation = (data, params) => {
    return this.axios({
      method: endpoints.patchNegotiation.method,
      url: endpoints.patchNegotiation.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  getClaimAndMetaData = currentClaimId => {
    return this.axios({
      method: endpoints.getClaimAndMetaData.method,
      url: 'ClaimMatterInformation/' + currentClaimId + '/' + endpoints.getClaimAndMetaData.url,
    });
  };
  postCloseClaimData = (currentId, data) => {
    return this.axios({
      method: endpoints.postCloseClaimData.method,
      url: endpoints.postCloseClaimData.url + currentId,
      data: JSON.stringify({ ...data }),
    });
  };
  getBudgetRates = currentClaimId => {
    return this.axios({
      method: endpoints.getBudgetRates.method,
      url: endpoints.getBudgetRates.url + currentClaimId + '/Budget/GetBudgetRates',
    });
  };
  addDocumentFile = (currentId, data) => {
    return this.axios({
      method: endpoints.addDocumentFile.method,
      url: endpoints.addDocumentFile.url + currentId + '/Documents/addfile',
      data: JSON.stringify({ ...data }),
    });
  };

  addMultipleDocumentFile = (currentId, data) => {
    return this.axios({
      method: endpoints.addDocumentFile.method,
      url: endpoints.addDocumentFile.url + currentId + '/Documents/addmultiplefiles',
      data: JSON.stringify({ ...data }),
    });
  };

  addCommonMultipleDocumentFile = data => {
    return this.axios({
      method: endpoints.addCommonMultipleDocumentFile.method,
      url: endpoints.addCommonMultipleDocumentFile.url,
      data: JSON.stringify({ ...data }),
    });
  };

  addDocumentFolder = (currentId, data) => {
    return this.axios({
      method: endpoints.addDocumentFolder.method,
      url: endpoints.addDocumentFolder.url + currentId + '/Documents',
      data: JSON.stringify({ ...data }),
    });
  };
  deleteFileFolder = (currentId, data) => {
    return this.axios({
      method: endpoints.deleteFileFolder.method,
      url: endpoints.deleteFileFolder.url + currentId + '/Documents/destroy_folder_file',
      data: JSON.stringify({ ...data }),
    });
  };

  deletePermanentFileFolder = (currentId, data) => {
    return this.axios({
      method: endpoints.deletePermanentFileFolder.method,
      url: endpoints.deletePermanentFileFolder.url + currentId + '/Documents/delete_permanently',
      data: JSON.stringify({ ...data }),
    });
  };

  getAllDocument = currentClaimId => {
    return this.axios({
      method: endpoints.getAllDocument.method,
      url: endpoints.getAllDocument.url + currentClaimId + '/Documents',
    });
  };

  getDropdownFilesFolder = currentClaimId => {
    return this.axios({
      method: endpoints.getDropdownFilesFolder.method,
      url: endpoints.getDropdownFilesFolder.url + currentClaimId + '/Documents/DropdownFilesFolder',
    });
  };

  renameFileFolder = (currentId, FileId, data, params) => {
    return this.axios({
      method: endpoints.renameFileFolder.method,
      url: endpoints.renameFileFolder.url + currentId + '/Documents/RenameFileOrFolder/' + FileId,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  downloadFileFolder = (currentId, data) => {
    return this.axios({
      method: endpoints.downloadFileFolder.method,
      url: endpoints.downloadFileFolder.url + currentId + '/Documents/download_action_details',
      data: JSON.stringify({ ...data }),
    });
  };

  recoverDeleteFileFolder = (currentId, data) => {
    return this.axios({
      method: endpoints.recoverDeleteFileFolder.method,
      url: endpoints.recoverDeleteFileFolder.url + currentId + '/Documents/recover_folders_files',
      data: JSON.stringify({ ...data }),
    });
  };

  recoverDeleteFileFolder = (currentId, data) => {
    return this.axios({
      method: endpoints.recoverDeleteFileFolder.method,
      url: endpoints.recoverDeleteFileFolder.url + currentId + '/Documents/recover_folders_files',
      data: JSON.stringify({ ...data }),
    });
  };

  moveFileFolder = (currentId, data) => {
    return this.axios({
      method: endpoints.moveFileFolder.method,
      url: endpoints.moveFileFolder.url + currentId + '/Documents/MoveFileOrFolder',
      data: JSON.stringify({ ...data }),
    });
  };
  logOutCall = () => {
    return this.axios({
      method: endpoints.logOutCall.method,
      url: endpoints.logOutCall.url,
    });
  };
  getNegotiationGraph = (id, params) => {
    return this.axios({
      method: endpoints.getNegotiationGraph.method,
      url: endpoints.getNegotiationGraph.url + id + '/get_graph_data',
      params,
    });
  };
  recoverRootFileFolder = (currentId, data) => {
    return this.axios({
      method: endpoints.recoverRootFileFolder.method,
      url:
        endpoints.recoverRootFileFolder.url +
        currentId +
        '/Documents/recover_folders_files_to_root',
      data: JSON.stringify({ ...data }),
    });
  };
  getTaskMetaData = params => {
    return this.axios({
      method: endpoints.getTaskMetaData.method,
      url: endpoints.getTaskMetaData.url,
      params,
    });
  };
  getTaskClaimsData = params => {
    return this.axios({
      method: endpoints.getTaskClaimDropdown.method,
      url: endpoints.getTaskClaimDropdown.url,
      params,
    });
  };
  getTaskAssigneesData = params => {
    return this.axios({
      method: endpoints.getTaskAssigneeDropdown.method,
      url: endpoints.getTaskAssigneeDropdown.url,
      params,
    });
  };
  getTaskAuthoritysData = params => {
    return this.axios({
      method: endpoints.getTaskAuthorityDropdown.method,
      url: endpoints.getTaskAuthorityDropdown.url,
      params,
    });
  };
  postTask = data => {
    return this.axios({
      method: endpoints.postTask.method,
      url: endpoints.postTask.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getGlobalTaskCall = params => {
    return this.axios({
      method: endpoints.getGlobalTaskCall.method,
      url: endpoints.getGlobalTaskCall.url,
      params,
    });
  };
  getGlobalMatterTaskCall = (claimId, params) => {
    return this.axios({
      method: endpoints.getGlobalMatterTaskCall.method,
      url: endpoints.getGlobalMatterTaskCall.url + claimId,
      params,
    });
  };
  allNotification = params => {
    return this.axios({
      method: endpoints.allNotification.method,
      url: endpoints.allNotification.url,
      params,
    });
  };
  notificationStatus = () => {
    return this.axios({
      method: endpoints.notificationStatus.method,
      url: endpoints.notificationStatus.url,
    });
  };
  updateNotification = (notificationId, data) => {
    return this.axios({
      method: endpoints.updateNotification.method,
      url: endpoints.updateNotification.url + notificationId,
      data: JSON.stringify({ ...data }),
    });
  };
  allGlobalNotification = params => {
    return this.axios({
      method: endpoints.allGlobalNotification.method,
      url: endpoints.allGlobalNotification.url,
      params,
    });
  };
  dismissAllNotification = params => {
    return this.axios({
      method: endpoints.dismissAllNotification.method,
      url: endpoints.dismissAllNotification.url,
      params,
    });
  };
  readAllNotification = params => {
    return this.axios({
      method: endpoints.readAllNotification.method,
      url: endpoints.readAllNotification.url,
      params,
    });
  };
  dismissNotification = (id, data) => {
    return this.axios({
      method: endpoints.dismissNotification.method,
      url: endpoints.dismissNotification.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  deleteTask = id => {
    return this.axios({
      method: endpoints.deleteTask.method,
      url: endpoints.deleteTask.url + id,
    });
  };
  addArchiveCall = id => {
    return this.axios({
      method: endpoints.addArchiveCall.method,
      url: endpoints.addArchiveCall.url + id,
    });
  };
  addUndoCompleteCall = id => {
    return this.axios({
      method: endpoints.addUndoCompleteCall.method,
      url: endpoints.addUndoCompleteCall.url + id,
    });
  };
  addUndoArchiveCall = id => {
    return this.axios({
      method: endpoints.addUndoArchiveCall.method,
      url: endpoints.addUndoArchiveCall.url + id,
    });
  };
  approveRequestCall = id => {
    return this.axios({
      method: endpoints.approveRequestCall.method,
      url: endpoints.approveRequestCall.url + id,
    });
  };
  getNotes = id => {
    return this.axios({
      method: endpoints.getNotes.method,
      url: endpoints.getNotes.url + id,
    });
  };
  postNotes = (id, data) => {
    return this.axios({
      method: endpoints.postNotes.method,
      url: endpoints.postNotes.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  completeTaskCall = id => {
    return this.axios({
      method: endpoints.completeTaskCall.method,
      url: endpoints.completeTaskCall.url + id,
    });
  };
  rejectRequestCall = (id, data) => {
    return this.axios({
      method: endpoints.rejectRequestCall.method,
      url: endpoints.rejectRequestCall.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  MoveNotNeededTask = id => {
    return this.axios({
      method: endpoints.MoveNotNeededTask.method,
      url: endpoints.MoveNotNeededTask.url + id,
    });
  };
  UndoRejectCall = id => {
    return this.axios({
      method: endpoints.UndoRejectCall.method,
      url: endpoints.UndoRejectCall.url + id,
    });
  };
  UndoApproveCall = id => {
    return this.axios({
      method: endpoints.UndoApproveCall.method,
      url: endpoints.UndoApproveCall.url + id,
    });
  };
  ResendRequest = (id, data) => {
    return this.axios({
      method: endpoints.ResendRequest.method,
      url: endpoints.ResendRequest.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  getAuditData = (params, id) => {
    return this.axios({
      method: endpoints.getAuditData.method,
      url: id ? endpoints.getAuditData.url + id : endpoints.getAuditData.url,
      params,
    });
  };
  exportHistoricalData = params => {
    return this.axios({
      method: endpoints.exportHistoricalData.method,
      url: endpoints.exportHistoricalData.url,
      params,
    });
  };
  getHistoryDot = (params, id) => {
    return this.axios({
      method: endpoints.getHistoryDot.method,
      url: endpoints.getHistoryDot.url,
      params,
    });
  };
  getRolesAndContactTypesMetaData = () => {
    return this.axios({
      method: endpoints.getRolesAndContactTypesMetaData.method,
      url: endpoints.getRolesAndContactTypesMetaData.url,
    });
  };
  postImportUser = data => {
    return this.axios({
      method: endpoints.postImportUser.method,
      url: endpoints.postImportUser.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getOurTeamMembers = params => {
    return this.axios({
      method: endpoints.getOurTeamMembers.method,
      url: endpoints.getOurTeamMembers.url,
      params,
    });
  };
  getAllQuickLinks = params => {
    return this.axios({
      method: endpoints.getAllQuickLinks.method,
      url: endpoints.getAllQuickLinks.url,
      params,
    });
  };
  postQuickLinks = data => {
    return this.axios({
      method: endpoints.postQuickLinks.method,
      url: endpoints.postQuickLinks.url,
      data: JSON.stringify({ ...data }),
    });
  };
  updateQuickLinks = (id, data) => {
    return this.axios({
      method: endpoints.updateQuickLinks.method,
      url: endpoints.updateQuickLinks.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  updateQuickLinksOrder = data => {
    return this.axios({
      method: endpoints.updateQuickLinksOrder.method,
      url: endpoints.updateQuickLinksOrder.url,
      data: JSON.stringify({ ...data }),
    });
  };
  deleteQuickLinks = id => {
    return this.axios({
      method: endpoints.deleteQuickLinks.method,
      url: endpoints.deleteQuickLinks.url + id,
    });
  };

  getAllUserQuickLinks = params => {
    return this.axios({
      method: endpoints.getAllUserQuickLinks.method,
      url: endpoints.getAllUserQuickLinks.url,
      params,
    });
  };
  postUserQuickLinks = data => {
    return this.axios({
      method: endpoints.postUserQuickLinks.method,
      url: endpoints.postUserQuickLinks.url,
      data: JSON.stringify({ ...data }),
    });
  };
  updateUserQuickLinks = (id, data) => {
    return this.axios({
      method: endpoints.updateUserQuickLinks.method,
      url: endpoints.updateUserQuickLinks.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  updateUserQuickLinksOrder = data => {
    return this.axios({
      method: endpoints.updateUserQuickLinksOrder.method,
      url: endpoints.updateUserQuickLinksOrder.url,
      data: JSON.stringify({ ...data }),
    });
  };
  deleteUserQuickLinks = id => {
    return this.axios({
      method: endpoints.deleteUserQuickLinks.method,
      url: endpoints.deleteUserQuickLinks.url + id,
    });
  };

  getAuditData = (params, id) => {
    return this.axios({
      method: endpoints.getAuditData.method,
      url: id ? endpoints.getAuditData.url + id : endpoints.getAuditData.url,
      params,
    });
  };
  markAllReadHistory = params => {
    return this.axios({
      method: endpoints.markAllReadHistory.method,
      url: 'history/read_all_notifications',
      params,
    });
  };
  markSingleReadHistory = id => {
    return this.axios({
      method: endpoints.markSingleReadHistory.method,
      url: 'history/' + id + '/read_notification',
    });
  };
  getMessengerchannels = () => {
    return this.axios({
      method: endpoints.getMessengerchannels.method,
      url: endpoints.getMessengerchannels.url,
    });
  };
  getAccountInformation = () => {
    return this.axios({
      method: endpoints.getAccountInformation.method,
      url: endpoints.getAccountInformation.url,
    });
  };
  updateAccountInformation = data => {
    return this.axios({
      method: endpoints.updateAccountInformation.method,
      url: endpoints.updateAccountInformation.url,
      data: JSON.stringify({ ...data }),
    });
  };
  updateUserTimeZone = data => {
    return this.axios({
      method: endpoints.updateUserTimeZone.method,
      url: endpoints.updateUserTimeZone.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getNewSSO = () => {
    return this.axios({
      method: endpoints.getNewSSO.method,
      url: endpoints.getNewSSO.url,
    });
  };
  getGlobalSearchData = params => {
    return this.axios({
      method: endpoints.getGlobalSearchData.method,
      url: endpoints.getGlobalSearchData.url,
      params,
    });
  };
  patchClaimValuationByPlaintiff = (data, id) => {
    return this.axios({
      method: endpoints.patchClaimValuationByPlaintiff.method,
      url: endpoints.patchClaimValuationByPlaintiff.url + '/' + id,
      data: JSON.stringify({ ...data }),
    });
  };
  getRecentSearchData = params => {
    return this.axios({
      method: endpoints.getRecentSearchData.method,
      url: endpoints.getRecentSearchData.url,
      params,
    });
  };
  postRecentSearchData = data => {
    return this.axios({
      method: endpoints.postRecentSearchData.method,
      url: endpoints.postRecentSearchData.url,
      data: JSON.stringify({ ...data }),
    });
  };
  updateTask = (currentId, data) => {
    return this.axios({
      method: endpoints.updateTask.method,
      url: endpoints.updateTask.url + currentId,
      data: JSON.stringify({ ...data }),
    });
  };
  GetCloseMatterDetails = currentId => {
    return this.axios({
      method: endpoints.GetCloseMatterDetails.method,
      url: endpoints.GetCloseMatterDetails.url + currentId,
    });
  };
  getContactDropdownData = params => {
    return this.axios({
      method: endpoints.getContactDropdownData.method,
      url: endpoints.getContactDropdownData.url,
      params,
    });
  };
  getContactById = (id, params) => {
    return this.axios({
      method: endpoints.getContactById.method,
      url: endpoints.getContactById.url + id,
      params,
    });
  };
  patchCaseStatus = (id, data) => {
    return this.axios({
      method: endpoints.patchCaseStatus.method,
      url: endpoints.patchCaseStatus.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  getRoleAndContactTypeMetaData = () => {
    return this.axios({
      method: endpoints.getRoleAndContactTypeMetaData.method,
      url: endpoints.getRoleAndContactTypeMetaData.url,
    });
  };
  getConfigValue = params => {
    return this.axios({
      method: endpoints.getConfigValue.method,
      url: endpoints.getConfigValue.url,
      params,
    });
  };
  updateGlobalValuationNotes = (id, data) => {
    return this.axios({
      method: endpoints.updateGlobalValuationNotes.method,
      url: endpoints.updateGlobalValuationNotes.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  updateBudgetNotes = (id, data) => {
    return this.axios({
      method: endpoints.updateBudgetNotes.method,
      url: endpoints.updateBudgetNotes.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  updateLiensNotes = (id, data) => {
    return this.axios({
      method: endpoints.updateLiensNotes.method,
      url: endpoints.updateLiensNotes.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  updatePossibleCoverageNotes = (id, data) => {
    return this.axios({
      method: endpoints.updatePossibleCoverageNotes.method,
      url: endpoints.updatePossibleCoverageNotes.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  multipartDocumentUpload = (id, data) => {
    return this.axios({
      method: endpoints.multipartDocumentUpload.method,
      url: endpoints.multipartDocumentUpload.url + id + '/Documents/uploadFile',
      data: data,
    });
  };
  getS3Url = data => {
    return this.axios({
      method: endpoints.getS3Url.method,
      url: endpoints.getS3Url.url,
      data: data,
    });
  };
  initializeMultipartUpload = (id, data) => {
    return this.axios({
      method: endpoints.multipartDocumentUpload.method,
      url: endpoints.multipartDocumentUpload.url + id + '/Documents/createMultipart',
      data: data,
    });
  };
  initializeCommonMultipartUpload = data => {
    return this.axios({
      method: endpoints.multipartCommonDocumentUpload.method,
      url: endpoints.multipartCommonDocumentUpload.url + 'createMultipart',
      data: data,
    });
  };
  multipartDocumentUpload = (id, data) => {
    return this.axios({
      method: endpoints.multipartDocumentUpload.method,
      url: endpoints.multipartDocumentUpload.url + id + '/Documents/uploadPart',
      data: data,
    });
  };
  multipartCommonDocumentUpload = data => {
    return this.axios({
      method: endpoints.multipartCommonDocumentUpload.method,
      url: endpoints.multipartCommonDocumentUpload.url + 'uploadPart',
      data: data,
    });
  };
  finalizeMultipartUpload = (id, data) => {
    return this.axios({
      method: endpoints.multipartDocumentUpload.method,
      url: endpoints.multipartDocumentUpload.url + id + '/Documents/completeMultipart',
      data: data,
    });
  };
  finalizeCommonMultipartUpload = data => {
    return this.axios({
      method: endpoints.multipartCommonDocumentUpload.method,
      url: endpoints.multipartCommonDocumentUpload.url + 'completeMultipart',
      data: data,
    });
  };
  abortMultipartUpload = (id, data) => {
    return this.axios({
      method: endpoints.multipartDocumentUpload.method,
      url: endpoints.multipartDocumentUpload.url + id + '/Documents/abortMultipartUpload',
      data: data,
    });
  };
  abortCommonMultipartUpload = data => {
    return this.axios({
      method: endpoints.multipartDocumentUpload.method,
      url: endpoints.multipartDocumentUpload.url + '/abortMultipartUpload',
      data: data,
    });
  };
  revokeAccess = (id, data) => {
    return this.axios({
      method: endpoints.revokeAccesData.method,
      url: endpoints.revokeAccesData.url + id + '/revoke_access',
      data: data,
    });
  };
  getClaimScore = params => {
    return this.axios({
      method: endpoints.getClaimScore.method,
      url: endpoints.getClaimScore.url,
      params,
    });
  };
  patchClaimScore = (currentId, data) => {
    return this.axios({
      method: endpoints.patchClaimScore.method,
      url: endpoints.patchClaimScore.url + currentId,
      data: JSON.stringify({ ...data }),
    });
  };
  getClaimScoreColumns = params => {
    return this.axios({
      method: endpoints.claimScoreColumns.method,
      url: endpoints.claimScoreColumns.url,
      params,
    });
  };
  // bulk access org
  getOrgData = params => {
    return this.axios({
      method: endpoints.getOrgData.method,
      url: endpoints.getOrgData.url,
      params,
    });
  };
  getPracData = params => {
    return this.axios({
      method: endpoints.getPracData.method,
      url: endpoints.getPracData.url,
      params,
    });
  };
  getClaimData = params => {
    return this.axios({
      method: endpoints.getClaimData.method,
      url: endpoints.getClaimData.url,
      params,
    });
  };
  getContactTypeData = params => {
    return this.axios({
      method: endpoints.getContactTypeData.method,
      url: endpoints.getContactTypeData.url,
      params,
    });
  };
  // contacts
  getBulkContactCall = (data, params) => {
    return this.axios({
      method: endpoints.getBulkContactCall.method,
      url: endpoints.getBulkContactCall.url,
      data: JSON.stringify({ ...data }),
      params,
    });
  };
  postBulkAccess = data => {
    return this.axios({
      method: endpoints.postBulkAccess.method,
      url: endpoints.postBulkAccess.url,
      data: JSON.stringify({ ...data }),
    });
  };
  listMessengerChannels = params => {
    return this.axios({
      method: endpoints.listMessengerChannels.method,
      url: endpoints.listMessengerChannels.url,
      params,
    });
  };
  addBackgroundExport = data => {
    return this.axios({
      method: endpoints.addBackgroundExport.method,
      url: endpoints.addBackgroundExport.url,
      data: JSON.stringify({ ...data }),
    });
  };
  backgroundExports = params => {
    return this.axios({
      method: endpoints.backgroundExports.method,
      url: endpoints.backgroundExports.url,
      params,
    });
  };
  backgroundExportsUpdate = (id, data) => {
    return this.axios({
      method: endpoints.backgroundExportsUpdate.method,
      url: endpoints.backgroundExportsUpdate.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  getEventCategories = params => {
    return this.axios({
      method: endpoints.getEventCategories.method,
      url: endpoints.getEventCategories.url,
      params,
    });
  };
  getEventCategoriesFilter = params => {
    return this.axios({
      method: endpoints.getEventCategoriesFilter.method,
      url: endpoints.getEventCategoriesFilter.url,
      params,
    });
  };
  addEventCategory = data => {
    return this.axios({
      method: endpoints.addEventCategory.method,
      url: endpoints.addEventCategory.url,
      data: JSON.stringify({ ...data }),
    });
  };
  updateEventCategory = (id, data) => {
    return this.axios({
      method: endpoints.updateEventCategory.method,
      url: endpoints.updateEventCategory.url + id,
      data: JSON.stringify({ ...data }),
    });
  };
  addEvent = data => {
    return this.axios({
      method: endpoints.addEvent.method,
      url: endpoints.addEvent.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getCommonDocuments = params => {
    return this.axios({
      method: endpoints.getCommonDocuments.method,
      url: endpoints.getCommonDocuments.url,
      params,
    });
  };
  addCommonDocuments = data => {
    return this.axios({
      method: endpoints.addCommonDocumentFile.method,
      url: endpoints.addCommonDocumentFile.url,
      data: JSON.stringify({ ...data }),
    });
  };
  getOverviewEvent = params => {
    return this.axios({
      method: endpoints.getOverviewEvent.method,
      url: endpoints.getOverviewEvent.url,
      params,
    });
  };
  getOverviewEventById = eventId => {
    return this.axios({
      method: endpoints.getOverviewEvent.method,
      url: endpoints.getOverviewEvent.url + `/${eventId}`,
    });
  };
  getEventList = params => {
    return this.axios({
      method: endpoints.getEventList.method,
      url: endpoints.getEventList.url,
      params,
    });
  };
  getUserEventFilters = params => {
    return this.axios({
      method: endpoints.getEventFilter.method,
      url: endpoints.getEventFilter.url,
      params,
    });
  };
  getFiltersCount = params => {
    return this.axios({
      method: endpoints.getOverviewEvent.method,
      url: endpoints.getOverviewEvent.url + `/get_filtered_events_count`,
      params,
    });
  };
  updateUserEventFilters = ({ params, data }) => {
    return this.axios({
      method: endpoints.updateEventFilter.method,
      url: endpoints.updateEventFilter.url,
      params,
      data: JSON.stringify({ ...data }),
    });
  };
  getEventCreators = params => {
    return this.axios({
      method: endpoints.getOverviewEvent.method,
      url: endpoints.getOverviewEvent.url + `/get_event_creators`,
      params,
    });
  };
  bulkEventUpdate = data => {
    return this.axios({
      method: endpoints.bulkEventUpdate.method,
      url: endpoints.bulkEventUpdate.url,
      data: JSON.stringify({ ...data }),
    });
  };
  deleteEventById = (eventId, pattern) => {
    return this.axios({
      method: endpoints.deleteEvent.method,
      url: endpoints.deleteEvent.url + `/${eventId}?pattern=${pattern}`,
    });
  };
  editEventById = (eventId, queryParams, data) => {
    return this.axios({
      method: endpoints.editEvent.method,
      url:
        endpoints.editEvent.url +
        `/${eventId}?pattern=${queryParams?.pattern}&edit_start_date=${queryParams?.edit_start_date}&edit_end_date=${queryParams?.edit_end_date}`,
      data: JSON.stringify({ ...data }),
    });
  };
  getEventFilterClaimContacts = params => {
    return this.axios({
      method: endpoints.getOverviewEvent.method,
      url: endpoints.getOverviewEvent.url + `/get_event_claim_contacts`,
      params,
    });
  };
  exportEvent = params => {
    return this.axios({
      method: endpoints.exportEvent.method,
      url: endpoints.exportEvent.url,
      params,
    });
  };
}
export default ApiUtils;
