import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { getStateFromCookie, setStateToCookie } from '../cookies/cookieHelper';
import { loadState, saveState } from '../localstorage/localstorageHelper';
import auditHistoryTypeReducer from '../reducers/auditHistoryReducer';
import AuthReducer from '../reducers/authReducer';
import CloseClaimReducer from '../reducers/closeClaimReducer';
import eventDetailsReducer from '../reducers/eventDetailsReducer';
import FormModelTypeReducer from '../reducers/formModelTypeReducer';
import GlobalContactReducer from '../reducers/globalContactReducer';
import GlobalNotificationTypeReducer from '../reducers/globalNotificationTypeReducer';
import GlobalSearchReducer from '../reducers/globalSearchReducer';
import InternetFlagReducer from '../reducers/internetFlagReducer';
import LocalSearchReducer from '../reducers/localSearchReducer';
import MessengerChatReducer from '../reducers/messengerChatReducer';
import NumberNotificationReducer from '../reducers/numberNotificationReducer';
import OverviewNotificationReducer from '../reducers/overviewNotificationReducer';
import QuickLinkReducer from '../reducers/quickLinkReducer';
import ReserveTypeReducer from '../reducers/reserveTypeReducer';
import StaticPageTypeReducer from '../reducers/staticPageReducer';
import TaskRequestCountReducer from '../reducers/taskRequestCountReducer';
import UserQuickLinkReducer from '../reducers/userQuickLinkReducer';
import UserTypeReducer from '../reducers/userTypeReducer';
import eventsFiltersReducer from '../reducers/eventsFiltersReducer';

import GlobalClaimAdjuster from '../reducers/claimAdjusterReducer';
import ClaimNameReducer from '../reducers/claimNameReducer';
import bulkEventUpdateReducer from '../reducers/bulkEventUpdateReducer';
const configStore = () => {
  let persistedStateCookie = getStateFromCookie();
  let persistedStateLocalstorage = {};
  if (persistedStateCookie && persistedStateCookie.auth && persistedStateCookie.auth.isLogin) {
    persistedStateLocalstorage = loadState();
  } else {
    saveState(persistedStateCookie);
    persistedStateLocalstorage = persistedStateCookie;
  }

  const store = createStore(
    combineReducers({
      auth: AuthReducer,
      userType: UserTypeReducer,
      staticPage: StaticPageTypeReducer,
      reserveType: ReserveTypeReducer,
      formModelType: FormModelTypeReducer,
      closeClaim: CloseClaimReducer,
      messengerChat: MessengerChatReducer,
      globalSearch: GlobalSearchReducer,
      notification: GlobalNotificationTypeReducer,
      quickLink: QuickLinkReducer,
      userQuickLink: UserQuickLinkReducer,
      auditHistory: auditHistoryTypeReducer,
      overviewNotification: OverviewNotificationReducer,
      numberNotification: NumberNotificationReducer,
      taskRequestCountReducer: TaskRequestCountReducer,
      globalContact: GlobalContactReducer,
      localSearch: LocalSearchReducer,
      currentClaimName: ClaimNameReducer,
      claimAdjuster: GlobalClaimAdjuster,
      internetFlag: InternetFlagReducer,
      eventDetails: eventDetailsReducer,
      eventsFilters: eventsFiltersReducer,
      bulkEventUpdate: bulkEventUpdateReducer,
    }),
    persistedStateLocalstorage,
    applyMiddleware(thunk)
  );

  store.subscribe(() => {
    setStateToCookie(store.getState());
    saveState(store.getState());
  });
  return store;
};

export default configStore;
