const endPoints = {
  getOverViewTaskData: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'get',
  },
  getDocumentDataByClaimId: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'get',
  },
  getBudgetDataByClaimId: {
    url: 'NumbersTab/get_budget_data',
    isProtected: true,
    method: 'get',
  },
  getReserveDataByClaimId: {
    url: 'NumbersTab/get_reserve_data',
    isProtected: true,
    method: 'get',
  },
  getClaimValuationByClaimId: {
    url: 'NumbersTab/get_valuation_data',
    isProtected: true,
    method: 'get',
  },
  getPossibleCoverageByClaimId: {
    url: 'NumbersTab/get_policy_data',
    isProtected: true,
    method: 'get',
  },
  getLiensDataByClaimId: {
    url: 'NumbersTab/get_liens_data',
    isProtected: true,
    method: 'get',
  },
  getQuickViewData: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'get',
  },
  getAllClaims: {
    url: 'ClaimMatterInformation',
    isProtected: true,
    method: 'get',
  },
  ColumnMetricsData: {
    url: 'ColumnMetricsData',
    isProtected: true,
    method: 'get',
  },
  getClaimById: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'get',
  },
  getClaimTopBarById: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'get',
  },
  getClaimValuationsById: {
    url: 'ClaimValuation',
    isProtected: true,
    method: 'get',
  },
  getClaimValuationsByPlaintiffId: {
    url: 'ClaimValuation/',
    isProtected: true,
    method: 'get',
  },
  getClaimValuationsForExport: {
    url: 'ClaimValuation/current_data_export_valuation',
    isProtected: true,
    method: 'get',
  },
  getLiensByPlaintiffId: {
    url: 'Liens/',
    isProtected: true,
    method: 'get',
  },
  getLiensDataForExport: {
    url: 'Liens/GetLiensDataForExport',
    isProtected: true,
    method: 'get',
  },
  getClaimNumberById: {
    url: 'ClaimMatterInformation/Numbers/',
    isProtected: true,
    method: 'get',
  },
  getLineById: {
    url: 'Liens',
    isProtected: true,
    method: 'get',
  },
  patchClaimById: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'patch',
  },
  getFilter: {
    url: 'Filters/get',
    isProtected: true,
    method: 'get',
  },
  updateFilter: {
    url: 'Filters/update',
    isProtected: true,
    method: 'patch',
  },
  getClaimTaskFilters: {
    url: 'Filters/GetClaimTaskFilters/',
    isProtected: true,
    method: 'get',
  },
  updateTaskFilter: {
    url: 'Filters/UpdateTaskFilters/',
    isProtected: true,
    method: 'patch',
  },
  updateSort: {
    url: 'Sorts/update',
    isProtected: true,
    method: 'patch',
  },
  getSort: {
    url: 'Sorts/get',
    isProtected: true,
    method: 'get',
  },
  columnMetrics: {
    url: 'ColumnMetrics/update',
    isProtected: true,
    method: 'patch',
  },
  columnMetricsGetdata: {
    url: 'ColumnMetrics/get',
    isProtected: true,
    method: 'get',
  },
  columnsUpdate: {
    url: 'Columns/update',
    isProtected: true,
    method: 'patch',
  },
  patchClaimViewDisplayDetails: {
    url: 'Columns/update_display_columns',
    isProtected: true,
    method: 'patch',
  },
  getStaticPageCall: {
    url: 'dynamicpage/',
    isProtected: true,
    method: 'get',
  },
  patchClaimValuationNote: {
    url: 'ClaimValuation/',
    isProtected: true,
    method: 'patch',
  },
  patchDamageByPlaintiff: {
    url: 'ClaimMatterInformation/Numbers/ClaimValuations/Damages/Bulk/Damages',
    isProtected: true,
    method: 'post',
  },
  patchLienByPlaintiff: {
    url: 'Liens/',
    isProtected: true,
    method: 'post',
  },
  addClaimByPlaintiff: {
    url: 'ClaimMatterInformation/Plaintiff/ClaimValuation',
    isProtected: true,
    method: 'post',
  },
  patchLiabilityByClaimValuation: {
    url: 'ClaimMatterInformation/Numbers/ClaimValuations/Liability/',
    isProtected: true,
    method: 'put',
  },
  getMatterContacts: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'get',
  },
  getUserRole: {
    url: 'User/me',
    isProtected: true,
    method: 'get',
  },
  addCustomCategoryForClaim: {
    url: 'configvalues',
    isProtected: true,
    method: 'post',
  },
  postRegisterStep: {
    url: 'User/user_registration',
    isProtected: false,
    method: 'post',
  },
  postResetPassword: {
    url: 'User/forgot_password',
    isProtected: false,
    method: 'post',
  },
  postLogin: {
    url: 'User/login',
    isProtected: false,
    method: 'post',
  },
  getTokenLogin: {
    url: 'User/GetDetailsOfToken/',
    isProtected: false,
    method: 'get',
  },
  postNewPassword: {
    url: 'User/reset_password',
    isProtected: false,
    method: 'post',
  },
  loggedUserResetPassword: {
    url: 'User/logged_user_reset_password',
    isProtected: true,
    method: 'post',
  },
  validatePassword: {
    url: 'User/validate_password_with_previous_passwords',
    isProtected: false,
    method: 'get',
  },
  checkToken: {
    url: 'User/check_token',
    isProtected: false,
    method: 'post',
  },
  updateReserve: {
    url: 'ClaimMatterInformation/Number/Reserve/',
    isProtected: true,
    method: 'put',
  },
  addReserve: {
    url: 'ClaimMatterInformation/Number/Reserve',
    isProtected: true,
    method: 'post',
  },
  getAllContactCall: {
    url: 'Contacts',
    isProtected: true,
    method: 'get',
  },
  createGlobalContact: {
    url: 'Contacts',
    isProtected: false,
    method: 'post',
  },
  addCustomCategoryForContact: {
    url: 'Contacts/create_configs',
    isProtected: true,
    method: 'post',
  },
  getAllClaimDropdownCall: {
    url: 'Contacts/get_claims_dropdown',
    isProtected: true,
    method: 'get',
  },
  updateBudgetBill: {
    url: 'ClaimMatterInformation/Numbers/',
    isProtected: true,
    method: 'patch',
  },
  getBudgetTypeAndCode: {
    url: 'budget-type-and-code',
    isProtected: true,
    method: 'get',
  },
  getBudget: {
    url: 'ClaimMatterInformation/Numbers/',
    isProtected: true,
    method: 'get',
  },
  addBudget: {
    url: 'ClaimMatterInformation/Numbers/',
    isProtected: true,
    method: 'post',
  },
  removeFromClaim: {
    url: 'ClaimContacts/',
    isProtected: true,
    method: 'get',
  },
  addBudgetTypeAndCode: {
    url: 'budget-type-and-code',
    isProtected: true,
    method: 'post',
  },
  ActivateGlobalContactStatusCall: {
    url: 'Contacts/',
    isProtected: true,
    method: 'patch',
  },
  ResendInviteContactCall: {
    url: 'Organization/OurTeamMembers/',
    isProtected: true,
    method: 'patch',
  },
  editGlobalContact: {
    url: 'Contacts/',
    isProtected: false,
    method: 'patch',
  },
  getClaimCoverageById: {
    url: 'ClaimMatterInformation/Numbers/PossibleCoverages/',
    isProtected: true,
    method: 'get',
  },
  addPolicyByDefendentId: {
    url: 'ClaimMatterInformation/Numbers/PossibleCoverages/Policy',
    isProtected: true,
    method: 'post',
  },
  editPolicyByDefendentId: {
    url: 'ClaimMatterInformation/Numbers/PossibleCoverages/Policy/',
    isProtected: true,
    method: 'put',
  },
  deletePolicyByDefendentId: {
    url: 'ClaimMatterInformation/Numbers/PossibleCoverages/Policy/',
    isProtected: true,
    method: 'delete',
  },
  getAllContactByClaimCall: {
    url: 'ClaimContacts',
    isProtected: true,
    method: 'get',
  },
  getCompanyContactByClaimDropCall: {
    url: 'ClaimContacts/get_person_or_company',
    isProtected: true,
    method: 'get',
  },
  editBudgetDetails: {
    url: 'ClaimMatterInformation/Numbers/',
    isProtected: true,
    method: 'post',
  },
  deleteBudget: {
    url: 'ClaimMatterInformation/Numbers/',
    isProtected: true,
    method: 'delete',
  },
  addContactForClaimAccess: {
    url: 'ClaimContacts/associate_claims_to_contact',
    isProtected: true,
    method: 'post',
  },
  createClaimContact: {
    url: 'ClaimContacts',
    isProtected: false,
    method: 'post',
  },
  getClaimDropDownMetaData: {
    url: 'ClaimMatterInformation/GetMetaData',
    isProtected: true,
    method: 'get',
  },
  addClaimMatterInformation: {
    url: 'ClaimMatterInformation',
    isProtected: true,
    method: 'post',
  },
  getAllDocumentByClaimCall: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'get',
  },
  getS3Token: {
    url: 'User/get_aws_token',
    isProtected: false,
    method: 'get',
  },
  getNegotiationById: {
    url: 'Negotiation',
    isProtected: true,
    method: 'get',
  },
  editNegotiationNote: {
    url: 'Negotiation/',
    isProtected: true,
    method: 'patch',
  },
  patchNegotiation: {
    url: 'Negotiation/bulk_negotiation_logs',
    isProtected: true,
    method: 'patch',
  },
  getClaimAndMetaData: {
    url: 'get_budget_estimate_drop',
    isProtected: true,
    method: 'get',
  },
  postCloseClaimData: {
    url: 'ClaimMatterInformation/CloseClaimMatter/',
    isProtected: true,
    method: 'post',
  },
  getBudgetRates: {
    url: 'ClaimMatterInformation/Numbers/',
    isProtected: true,
    method: 'get',
  },
  addDocumentFile: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  addDocumentFolder: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  deleteFileFolder: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  deletePermanentFileFolder: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  getAllDocument: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'get',
  },
  getDropdownFilesFolder: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'get',
  },
  renameFileFolder: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  downloadFileFolder: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  recoverDeleteFileFolder: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  moveFileFolder: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  logOutCall: {
    url: 'User/logout',
    isProtected: true,
    method: 'get',
  },
  getTaskMetaData: {
    url: 'Task/GetCreateTaskMetaData',
    isProtected: true,
    method: 'get',
  },
  getTaskClaimDropdown: {
    url: 'Task/get_claims_dropdown',
    isProtected: true,
    method: 'get',
  },
  getTaskAssigneeDropdown: {
    url: 'Task/get_assignee_dropdown',
    isProtected: true,
    method: 'get',
  },
  getTaskAuthorityDropdown: {
    url: 'Task/get_authority_dropdown',
    isProtected: true,
    method: 'get',
  },
  postTask: {
    url: 'Task',
    isProtected: true,
    method: 'post',
  },
  getNegotiationGraph: {
    url: 'Negotiation/',
    isProtected: true,
    method: 'get',
  },
  recoverRootFileFolder: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  getGlobalTaskCall: {
    url: 'Task',
    isProtected: true,
    method: 'get',
  },
  getGlobalMatterTaskCall: {
    url: 'Task/MatterTask/',
    isProtected: true,
    method: 'get',
  },
  allNotification: {
    url: 'notification',
    isProtected: true,
    method: 'get',
  },
  notificationStatus: {
    url: 'notification/get_notification_status',
    isProtected: true,
    method: 'get',
  },
  updateNotification: {
    url: 'notification/',
    isProtected: true,
    method: 'patch',
  },
  allGlobalNotification: {
    url: 'notification/get_global_notifications',
    isProtected: true,
    method: 'get',
  },
  dismissAllNotification: {
    url: 'notification/dismiss_all_notifications',
    isProtected: true,
    method: 'get',
  },
  dismissNotification: {
    url: 'notification/',
    isProtected: true,
    method: 'delete',
  },
  readAllNotification: {
    url: 'notification/read_all_notifications',
    isProtected: true,
    method: 'get',
  },
  deleteTask: {
    url: 'Task/',
    isProtected: true,
    method: 'delete',
  },
  addArchiveCall: {
    url: 'Task/AddToArchive/',
    isProtected: true,
    method: 'get',
  },
  addUndoCompleteCall: {
    url: 'Task/UndoComplete/',
    isProtected: true,
    method: 'get',
  },
  addUndoArchiveCall: {
    url: 'Task/UndoArchive/',
    isProtected: true,
    method: 'get',
  },
  approveRequestCall: {
    url: 'Task/ApproveTask/',
    isProtected: true,
    method: 'get',
  },
  getNotes: {
    url: 'Task/GetTaskNotes/',
    isProtected: true,
    method: 'get',
  },
  postNotes: {
    url: 'Task/TaskNotes/',
    isProtected: true,
    method: 'post',
  },
  completeTaskCall: {
    url: 'Task/CompleteTask/',
    isProtected: true,
    method: 'put',
  },
  rejectRequestCall: {
    url: 'Task/RejectRequest/',
    isProtected: true,
    method: 'post',
  },
  MoveNotNeededTask: {
    url: 'Task/NotNeeded/',
    isProtected: true,
    method: 'get',
  },
  UndoApproveCall: {
    url: 'Task/UndoApprove/',
    isProtected: true,
    method: 'get',
  },
  UndoRejectCall: {
    url: 'Task/UndoReject/',
    isProtected: true,
    method: 'get',
  },
  ResendRequest: {
    url: 'Task/ResendRequest/',
    isProtected: true,
    method: 'post',
  },
  getAuditData: {
    url: 'history',
    isProtected: true,
    method: 'get',
  },
  exportHistoricalData: {
    url: 'history/export_historical_data',
    isProtected: true,
    method: 'get',
  },
  getHistoryDot: {
    url: 'history/get_history_dots',
    isProtected: true,
    method: 'get',
  },
  getRolesAndContactTypesMetaData: {
    url: 'Organization/OurTeamMembers/RolesAndContactTypesMetaData',
    isProtected: true,
    method: 'get',
  },
  postImportUser: {
    url: 'Organization/OurTeamMembers/ImportUser',
    isProtected: true,
    method: 'post',
  },
  getOurTeamMembers: {
    url: 'Organization/OurTeamMembers',
    isProtected: true,
    method: 'get',
  },
  getAllQuickLinks: {
    url: 'QuickLinks',
    isProtected: true,
    method: 'get',
  },
  postQuickLinks: {
    url: 'QuickLinks',
    isProtected: true,
    method: 'post',
  },
  updateQuickLinks: {
    url: 'QuickLinks/',
    isProtected: true,
    method: 'patch',
  },
  updateQuickLinksOrder: {
    url: 'QuickLinks/UpdateOrder',
    isProtected: true,
    method: 'patch',
  },
  deleteQuickLinks: {
    url: 'QuickLinks/',
    isProtected: true,
    method: 'delete',
  },

  getAllUserQuickLinks: {
    url: 'UserQuickLinks',
    isProtected: true,
    method: 'get',
  },
  postUserQuickLinks: {
    url: 'UserQuickLinks',
    isProtected: true,
    method: 'post',
  },
  updateUserQuickLinks: {
    url: 'UserQuickLinks/',
    isProtected: true,
    method: 'patch',
  },
  updateUserQuickLinksOrder: {
    url: 'UserQuickLinks/UpdateOrder',
    isProtected: true,
    method: 'patch',
  },
  deleteUserQuickLinks: {
    url: 'UserQuickLinks/',
    isProtected: true,
    method: 'delete',
  },
  markAllReadHistory: {
    url: 'history/read_all_notifications​',
    isProtected: true,
    method: 'get',
  },
  markSingleReadHistory: {
    url: 'history/',
    isProtected: true,
    method: 'get',
  },
  getMessengerchannels: {
    url: 'MessengerGroup',
    isProtected: true,
    method: 'get',
  },
  getAccountInformation: {
    url: 'User/me',
    isProtected: true,
    method: 'get',
  },
  getNewVerificationCode: {
    url: 'User/resend_code',
    isProtected: false,
    method: 'get',
  },
  updateAccountInformation: {
    url: 'User/UpdateUser',
    isProtected: true,
    method: 'patch',
  },

  getNewSSO: {
    url: 'User/generate_new_zendesk_url',
    isProtected: true,
    method: 'get',
  },
  getGlobalSearchData: {
    url: 'globalsearch',
    isProtected: true,
    method: 'get',
  },
  patchClaimValuationByPlaintiff: {
    url: 'ClaimMatterInformation/Plaintiff/ClaimValuation',
    isProtected: true,
    method: 'patch',
  },
  getRecentSearchData: {
    url: 'recentlyviewed',
    isProtected: true,
    method: 'get',
  },
  postRecentSearchData: {
    url: 'recentlyviewed',
    isProtected: true,
    method: 'post',
  },
  getS3Url: {
    url: 'User/upload_file_to_s3',
    isProtected: true,
    method: 'post',
  },
  updateTask: {
    url: 'Task/UpdateTask/',
    isProtected: true,
    method: 'patch',
  },
  GetCloseMatterDetails: {
    url: 'ClaimMatterInformation/GetCloseMatterDetails/',
    isProtected: true,
    method: 'get',
  },
  getContactDropdownData: {
    url: 'Contacts/get_claim_and_contact_types_dropdown',
    isProtected: true,
    method: 'get',
  },
  getContactById: {
    url: 'ClaimContacts/',
    isProtected: true,
    method: 'get',
  },
  patchCaseStatus: {
    url: 'ClaimMatterInformation/UpdateClaimMatterStatus/',
    isProtected: true,
    method: 'patch',
  },
  getRoleAndContactTypeMetaData: {
    url: 'ClaimContacts/get_roles_and_contact_types',
    isProtected: true,
    method: 'get',
  },
  getConfigValue: {
    url: 'configvalue',
    isProtected: true,
    method: 'get',
  },
  updateGlobalValuationNotes: {
    url: 'ClaimMatterInformation/UpdateClaimValuationsNotes/',
    isProtected: true,
    method: 'patch',
  },
  updateBudgetNotes: {
    url: 'ClaimMatterInformation/UpdateBudgetNotes/',
    isProtected: true,
    method: 'patch',
  },
  multipartDocumentUpload: {
    url: 'ClaimMatterInformation/',
    isProtected: true,
    method: 'post',
  },
  revokeAccesData: {
    url: 'Organization/OurTeamMembers/',
    isProtected: true,
    method: 'post',
  },
  updateLiensNotes: {
    url: 'ClaimMatterInformation/UpdateLiensNotes/',
    isProtected: true,
    method: 'patch',
  },
  updatePossibleCoverageNotes: {
    url: 'ClaimMatterInformation/UpdatePossibleCoveragesNotes/',
    isProtected: true,
    method: 'patch',
  },
  getClaimScore: {
    url: 'claimscore',
    isProtected: true,
    method: 'get',
  },
  patchClaimScore: {
    url: 'claimscore/',
    isProtected: true,
    method: 'patch',
  },
  claimScoreColumns: {
    url: 'claimscore/get_conditional_drop_down',
    isProtected: true,
    method: 'get',
  },
  getAIChatbotThreads: {
    url: 'get_user_threads',
    isProtected: true,
    method: 'get',
  },
  updateAIChatbotThread: {
    url: 'update_thread/',
    isProtected: true,
    method: 'put',
  },
  deleteAIChatbotThread: {
    url: 'delete_thread/',
    isProtected: true,
    method: 'delete',
  },
  getAIChatBotSamplePrompts: {
    url: 'get_sample_prompts',
    isProtected: true,
    method: 'get',
  },
  getAIChatbotThreadChat: {
    url: 'get_thread_chat/',
    isProtected: true,
    method: 'get',
  },
  createAIChatbotThread: {
    url: 'create_thread/',
    isProtected: true,
    method: 'post',
  },
  createAIChatbotThreadChat: {
    url: 'chat/',
    isProtected: true,
    method: 'post',
  },
  getAIChatbotSamplePrompts: {
    url: 'get_sample_prompts/',
    isProtected: true,
    method: 'get',
  },
  regenerateAIChatbotResponse: {
    url: 'regenerate_response/',
    isProtected: true,
    method: 'post',
  },
  getOrgData: {
    url: 'bulk_grant_revoke_access/get_organization',
    isProtected: true,
    method: 'get',
  },
  getPracData: {
    url: 'bulk_grant_revoke_access/get_practice_area',
    isProtected: true,
    method: 'get',
  },
  getClaimData: {
    url: 'bulk_grant_revoke_access/get_claims_api',
    isProtected: true,
    method: 'get',
  },
  getBulkContactCall: {
    url: 'bulk_grant_revoke_access/get_contact_api',
    isProtected: true,
    method: 'get',
  },
  postBulkAccess: {
    url: 'bulk_grant_revoke_access',
    isProtected: false,
    method: 'post',
  },
  getContactTypeData: {
    url: 'bulk_grant_revoke_access/get_contact_type',
    isProtected: true,
    method: 'get',
  },
  listMessengerChannels: {
    url: 'MessengerGroup/list_channels',
    isProtected: true,
    method: 'get',
  },
  backgroundExports: {
    url: 'export',
    isProtected: true,
    method: 'get',
  },
  backgroundExportsUpdate: {
    url: 'export/',
    isProtected: true,
    method: 'patch',
  },
  addBackgroundExport: {
    url: 'export',
    isProtected: true,
    method: 'post',
  },
  getEventCategories: {
    url: 'events_categories',
    isProtected: true,
    method: 'get',
  },
  getEventCategoriesFilter: {
    url: 'scheduled_events/get_event_categories_filter_dropdown',
    isProtected: true,
    method: 'get',
  },
  addEventCategory: {
    url: 'events_categories',
    isProtected: true,
    method: 'post',
  },
  updateEventCategory: {
    url: 'events_categories/',
    isProtected: true,
    method: 'patch',
  },
  addEvent: {
    url: 'events',
    isProtected: true,
    method: 'post',
  },
  getCommonDocuments: {
    url: 'common_documents',
    isProtected: true,
    method: 'get',
  },
  multipartCommonDocumentUpload: {
    url: 'common_documents/',
    isProtected: true,
    method: 'post',
  },
  addCommonDocumentFile: {
    url: 'common_documents/addfile',
    isProtected: true,
    method: 'post',
  },
  addCommonMultipleDocumentFile: {
    url: 'common_documents/addmultiplefiles',
    isProtected: true,
    method: 'post',
  },
  getOverviewEvent: {
    url: 'scheduled_events',
    isProtected: true,
    method: 'get',
  },
  getEventList: {
    url: 'scheduled_events',
    isProtected: true,
    method: 'get',
  },
  getEventFilter: {
    url: 'Filters/get',
    isProtected: true,
    method: 'get',
  },
  updateEventFilter: {
    url: 'Filters/update',
    isProtected: true,
    method: 'patch',
  },
  bulkEventUpdate: {
    url: 'bulk_event_update',
    isProtected: true,
    method: 'post',
  },
  deleteEvent: {
    url: 'scheduled_events',
    isProtected: true,
    method: 'delete',
  },
  editEvent: {
    url: 'scheduled_events',
    isProtected: true,
    method: 'patch',
  },
  exportEvent: {
    url: 'scheduled_events/export_event',
    isProtected: true,
    method: 'get',
  },
  updateUserTimeZone: {
    url: 'User/user_timezone_update',
    isProtected: true,
    method: 'patch',
  },
};
export default endPoints;
